import React, { useContext } from "react";
import { Box, Text, Flex, Heading } from "@chakra-ui/react";
import { GatsbyImage } from "gatsby-plugin-image";

import CTAButton from "../../components/CTAButton";
import TextPathAnimation from "../../components/TextPathAnimation";
import useWindowSize from "../../utitlities/useWindowSize";
import getImageSizes from "../../utitlities/getImageSizes";
import { AppContext } from "../../context";
import "../../styles/style.scss";

function HomeHero(props) {
  const windowSize = useWindowSize();
  const {
    accentHeader,
    mainHeader,
    italicHeader,
    ctaLeft,
    ctaRight,
    curveText,
    bgImage,
    bgImageTablet,
    bgImageMobile,
    ctaLeftModal,
  } = props;
  const sizes = getImageSizes(
    windowSize?.width,
    !!bgImage,
    !!bgImageTablet,
    !!bgImageMobile
  );

  return (
    <>
      {/* TEXT CONTENT */}
      <Box
        w="100%"
        h={{
          base: "auto",
          md: "800px",
          lg: "800px",
        }}
        overflow="hidden"
        position="relative"
        bgColor="white"
        className="home-hero-container"
      >
        <Flex
          w="100%"
          h={{ base: "99%", lg: "100%", lgs: "99%" }}
          position="absolute"
          zIndex="0"
        >
          {sizes?.isMobile && (
            <GatsbyImage
              image={bgImageMobile.asset.gatsbyImageData}
              alt={"wana hero image"}
            />
          )}
          {sizes?.isTablet && (
            <GatsbyImage
              image={bgImageTablet.asset.gatsbyImageData}
              alt={"wana hero image"}
            />
          )}
          {sizes?.isDesktop && (
            <GatsbyImage
              image={bgImage.asset.gatsbyImageData}
              alt={"wana hero image"}
            />
          )}
        </Flex>
        {/* TEXT CONTENT */}
        <Box w="100%" h="100%" position="absolute" zIndex="1">
          <Box
            w={{ base: "90%", ms: "88%", lg: "88%", xl: "80%" }}
            h="100%"
            mx={{ base: "auto" }}
            // border="1px"
          >
            <Flex
              maxW={{
                base: "1444px",
                xxl: "650px",
                sl: "750px",
                ssl: "800px",
                xsl: "900px",
                msl: "1000px",
                ml: "1050px",
                exl: "1100px",
              }}
              h="100%"
              flexDirection="column"
              // border="1px"
            >
              <Text
                maxW={{
                  base: "309px",
                  ssm: "314px",
                  ms: "357px",
                  msx: "379px",
                  md: "460px",
                  mds: "470px",
                  lg: "487px",
                  lgs: "527px",
                  xl: "568px",
                  xxl: "100%",
                }}
                color={{ base: "white" }}
                fontSize={{
                  base: "10px",
                  ms: "12px",
                  md: "16px",
                  xxl: "20px",
                  sl: "24px",
                  ssl: "28px",
                  xsl: "32px",
                  msl: "36px",
                  ml: "40px",
                  exl: "44px",
                }}
                fontWeight={{ base: "800", md: "800" }}
                letterSpacing={{ base: "3px", md: "4px" }}
                lineHeight={{ base: "20px", md: "20px", xxl: "1" }}
                textTransform="uppercase"
                pt={{
                  base: "110px",
                  ms: "125px",
                  msx: "16.74vw",
                  md: "17.8vw",
                  mds: "17.4vw",
                  lg: "17.2vw",
                  lgs: "17vw",
                  xl: "241px",
                  xxl: "16.74vw",
                }}
                pb={{ base: "6px", md: "10px" }}
                whiteSpace={{ base: "pre-line" }}
              >
                {accentHeader.headerText}
              </Text>

              <Heading
                maxW={{
                  base: "306px",
                  ssm: "311px",
                  ms: "357px",
                  msx: "381px",
                  md: "404px",
                  mds: "444px",
                  lg: "482px",
                  lgs: "530px",
                  xl: "571px",
                  xxl: "100%",
                }}
                color={mainHeader.textColor?.color || "#312669"}
                fontFamily="Outfit"
                fontSize={{
                  base: "32px",
                  ms: "36px",
                  msx: "38px",
                  md: "40px",
                  mds: "44px",
                  lg: "48px",
                  lgs: "52px",
                  xl: "56px",
                  xxl: "60px",
                  sl: "64px",
                  ssl: "68px",
                  xsl: "72px",
                  msl: "76px",
                  ml: "80px",
                  exl: "84px",
                }}
                fontWeight={{ base: "bold" }}
                letterSpacing={{ base: "-0.88px" }}
                lineHeight={{ base: "1" }}
                textTransform="uppercase"
                noOfLines={{ base: 3 }}
                whiteSpace={{ base: "pre-line" }}
              >
                {mainHeader.headerText}
              </Heading>

              {italicHeader?.headerText && (
                <div className="lato-font">
                  <Text
                    mt={{ base: "0", ms: "10px", md: "21px" }}
                    color={italicHeader.textColor?.color || "white"}
                    fontSize={{
                      base: "16px",
                      md: "20px",
                      xxl: "24px",
                      sl: "28px",
                      ssl: "32px",
                      xsl: "36px",
                      msl: "40px",
                      ml: "44px",
                    }}
                    fontStyle="italic"
                    letterSpacing={{ base: "0", md: "0" }}
                    lineHeight={{ base: "30px", md: "20px", xxl: "1" }}
                    style={{ textShadow: "0 0 10px 0 rgba(0,0,0,0.5)" }}
                    whiteSpace={{ base: "pre-line" }}
                  >
                    {italicHeader.headerText}
                  </Text>
                </div>
              )}

              {/* BUTTONS */}
              <Buttons
                ctaLeft={ctaLeft}
                ctaLeftModal={ctaLeftModal}
                ctaRight={ctaRight}
              />
            </Flex>
          </Box>
        </Box>

        {/* SVG WHITE CURVE */}
        <Box w="100vw" position="absolute" bottom="0">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 79">
            <path
              fill="#ffffff"
              fillOpacity="1"
              d="M -0.27 42.27 C 149.83 9.7 326.46 98.52 500.27 38 L 500 80 L 0 80 Z"
            ></path>
          </svg>
        </Box>

        {/* BOTTOM LINEAR GRADIENT - MOBILE */}
        <Box
          w="100%"
          h={{ base: "100px" }}
          position="absolute"
          bottom="35px"
          display={{ base: "none", ms: "none" }}
          style={{
            background:
              "linear-gradient(180deg, rgba(255,255,255,0) 0%, #FFFFFF 100%)",
          }}
        />
      </Box>

      {/* CURVED TEXT DESKTOP */}
      <Box
        w="100%"
        display={{ base: "none", md: "flex" }}
        transform={{
          md: "translateY(-10.8vw)",
          lg: "translateY(-10.8vw)",
          xl: "translateY(-10.8vw)",
        }}
      >
        <TextPathAnimation text={curveText} />
      </Box>

      {/* CURVED TEXT MOBILE */}
      <Box
        w="100%"
        display={{ base: "flex", md: "none" }}
        transform={{
          base: "translateY(-19.4vw)",
          ms: "translateY(-20vw)",
          msx: "translateY(-22vw)",
        }}
        // border="1px"
      >
        <TextPathAnimationMobile text={curveText} />
      </Box>
    </>
  );
}

const Buttons = ({ ctaLeft, ctaLeftModal, ctaRight }) => {
  const appContext = useContext(AppContext);
  const leftProps = {} as { [x: string]: any };

  if (ctaLeftModal) {
    leftProps.onClick = appContext?.actions?.buyNowModalControl;
  } else if (ctaLeft?.ctaRoute?.route) {
    leftProps.href = ctaLeft.ctaRoute.route || "/";
  }

  return (
    <Flex
      w={{ base: "max-content" }}
      mt={{
        base: "57px",
        ms: "21px",
        md: "21px",
        xxl: "25px",
        sl: "29px",
        ssl: "33px",
        xsl: "38px",
        msl: "44px",
        ml: "51px",
      }}
      direction={{ base: "row" }}
      mx={{ base: "auto", ssm: "0", ms: "0", md: "0" }}
      justifyContent={{
        base: "space-between",
        ssm: "normal",
        ms: "normal",
        md: "normal",
      }}
      zIndex="2"
    >
      {/* CTA LEFT */}
      <Box mr={{ base: "0px", sm: "0px", ssm: "10px", ms: "10px", md: "10px" }}>
        <CTAButton
          {...leftProps}
          width={{
            base: "42.22vw",
            ssm: "152px",
            md: "160px",
            ssl: "184px",
            xsl: "212px",
            msl: "244px",
            ml: "281px",
          }}
          height={{
            base: "48px",
            md: "48px",
            ssl: "55px",
            xsl: "63px",
            msl: "73px",
            ml: "84px",
          }}
          fontSize={{
            base: "11px",
            md: "13px",
            ssl: "16px",
            xsl: "18px",
            msl: "20px",
            ml: "22px",
          }}
          fontWeight="900"
          bgColor={ ctaLeft?.isSolid ? ctaLeft.bgColor?.color || "primaryLighter" : { base: "transparent", msx: "transparent" }}
          color={ctaLeft.textColor?.color || "white"}
          _hover={{ bg: "primaryMenu", borderColor: "primaryMenu", color: "white" }}
          ctaText={ctaLeft.text}
          border={"2px"}
          borderColor={
            ctaLeft?.isSolid
              ? ctaLeft.bgColor?.color || "primaryLighter"
              : ctaLeft.textColor?.color || "primaryLighter"
          }
        />
      </Box>

      {/* CTA RIGHT */}
      <Box
      // background={{
      //   base:'linear-gradient(180deg, rgba(255,255,255,0) 1%, #FFFFFF 100%)',
      //   hhg:'none',
      // }}
      >
        <CTAButton
          href={ctaRight.ctaRoute.route || "/"}
          width={{
            base: "42.22vw",
            ssm: "152px",
            md: "160px",
            ssl: "184px",
            xsl: "212px",
            msl: "244px",
            ml: "281px",
          }}
          height={{
            base: "48px",
            md: "48px",
            ssl: "55.2px",
            xsl: "63px",
            msl: "73px",
            ml: "84px",
          }}
          fontSize={{
            base: "11px",
            md: "13px",
            ssl: "16px",
            xsl: "18px",
            msl: "20px",
            ml: "22px",
          }}
          fontWeight="900"
          color={ctaRight.textColor?.color || "primaryLighter"}
          bgColor={
            ctaRight?.isSolid
              ? ctaRight.bgColor?.color || "primaryLighter"
              : { base: "transparent", msx: "transparent" }
          }
          _hover={{ bg: "primaryMenu", borderColor: "primaryMenu", color: "white" }}
          ctaText={ctaRight.text}
          border={"2px"}
          borderColor={
            ctaRight?.isSolid
              ? ctaRight.bgColor?.color || "primaryLighter"
              : ctaRight.textColor?.color || "primaryLighter"
          }
        />
      </Box>
    </Flex>
  );
};

const TextPathAnimationMobile = ({ text }) => {
  const displayText = text.reduce((a, c) => (a === "" ? c : `${a}. ${c}`), "");
  //d="M0.28,46.88 C68.56,26.16 139.67,80.44 217.55,36.03 L218.11,154.45 L0.00,150.00 Z"
  // const multText = (displayText + ' ').repeat(100);
  // console.log(displayText);
  return (
    <Box
      w="100%"
      overflow="hidden"
      fontSize={{ base: "16px", ms: "14px", msx: "9px", md: "9px" }}
      fontWeight="700"
      textTransform="uppercase"
      style={{
        fontFamily: "Nunito Sans,sans-serif",
        userSelect: "none",
        // transform: 'scale(2)',
      }}
      dangerouslySetInnerHTML={{
        __html: `
        <svg width="100%" height="100%" viewBox="0 0 200 79">
          <path id="curveM" fill="transparent" d="M0.28,46.88 C68.56,26.16 144.74,74.52 217.55,36.03 0.11,120.00 0.00, 120.00 Z"></path>
          <text fill="#DDDBE8">
              <textPath xlink:href="#curveM">
                ${displayText} ${displayText}
                <animate id="anim1" attributeName="startOffset"
                    from="20"to="-465"
                    begin="0s;anim1.end" dur="8s"
                    repeatCount="indefinite" 
                  />
               </textPath>
          </text>
        </svg>
        `,
      }}
    ></Box>
  );
};

export default HomeHero;
