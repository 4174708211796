import * as React from 'react';
import { Box } from '@chakra-ui/react';

function TextPathAnimation({ text }) {
  const displayText = text.reduce((a, c) => (a === '' ? c : `${a}. ${c}`), '');

  // Function to adjust font size based on text length
  const calculateFontSize = (length) => {
    const baseFontSize = 16; // Default font size
    const threshold = 40; // Threshold for when to shrink the text size
    return length > threshold ? `12px` : `${baseFontSize}px`;
  };

  // Function to generate more &nbsp based on text length
  const generateNbsp = (length) => {
    const baseLength = 45; // Base length for ideal text
    const spacesNeeded = length > baseLength ? (length - baseLength) * 1.5 : (baseLength - length) * 3;
    return '&nbsp;'.repeat(Math.max(spacesNeeded, 5)); // Ensure at least 5 spaces
  };

  const fontSize = calculateFontSize(displayText.length); // Dynamically adjust font size
  const spacing = generateNbsp(displayText.length);

  return (
    <Box
      w="100%"
      overflow="hidden"
      fontSize={fontSize} // Apply dynamic font size here
      fontWeight="700"
      textTransform="uppercase"
      style={{
        fontFamily: 'Nunito Sans, sans-serif',
        userSelect: 'none',
      }}
      dangerouslySetInnerHTML={{
        __html: `
        <svg width="100%" height="100%" viewBox="0 0 500 79"> <!-- Original viewBox -->
          <path id="curve" fill="transparent" d="M -0.27 42.27 C 149.83 9.7 326.46 98.52 500.27 38 L 500 80 L 0 80 L -0.27 42.27 C 149.83 9.7 326.46 98.52 500.27 38 Z"></path>
          <text fill="#DDDBE8">
              <textPath xlink:href="#curve" startOffset="0">
                      ${displayText} ${spacing} ${displayText} 
                   <animate id="anim1" attributeName="startOffset"
                     from="500" to="0"
                     begin="0s;anim2.end" dur="10s"
                     repeatCount="indefinite" 
                   />
               </textPath>
          </text>
        </svg>
        `,
      }}
    ></Box>
  );
}

export default TextPathAnimation;
